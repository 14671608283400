import { useCallback, useReducer } from "react";
import "./App.css";
import Board from "./components/Board";
import IntroModal from "./components/IntroModal";
import ResultsModal from "./components/ResultsModal";

function App() {
  const [gameState, dispatchGameState] = useReducer(
    (state, action) => {
      if (action.type === "START") {
        return { started: true, finished: false, level: action.val, result: 0 };
      }
      if (action.type === "FINISH") {
        return { ...state, finished: true, result: action.val };
      }
      if (action.type === "RESTART") {
        return {
          started: false,
          finished: false,
          level: state.level,
          result: null,
        };
      }
    },
    { started: false, finished: false, level: "medium", result: null }
  );

  const closeIntroModalHandler = (level) => {
    dispatchGameState({ type: "START", val: level });
  };

  const gameFinishHandler = useCallback((result) => {
    dispatchGameState({ type: "FINISH", val: result });
  }, []);

  const restartGameHandler = () => {
    dispatchGameState({ type: "RESTART" });
  };

  return (
    <div className="App">
      {!gameState.started && (
        <IntroModal
          defaultLevel={gameState.level}
          onClose={closeIntroModalHandler}
        />
      )}
      {gameState.started && (
        <Board level={gameState.level} onFinish={gameFinishHandler} mode='manual' />
      )}
      {gameState.finished && (
        <ResultsModal
          resultInMs={gameState.result}
          onRestart={restartGameHandler}
        />
      )}
    </div>
  );
}

export default App;
