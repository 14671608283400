import React from "react";
import Button from "./UI/Button";
import Card from "./UI/Card";

import classes from "./IntroModal.module.css";

const ResultsModal = (props) => {
  return (
    <>
      <div className={classes.backdrop} />
      <div className={classes.modalContainer}>
        <Card className={classes.modal}>
          <p>
            Your took on average {props.resultInMs} ms per round.
          </p>
          <Button onClick={props.onRestart}>Restart Game</Button>
        </Card>
      </div>
    </>
  );
};

export default ResultsModal;
