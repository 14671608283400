import React, { useCallback, useEffect, useReducer, useState } from "react";
import Tile from "./Tile";
import { Math } from "globalthis/implementation";
import Card from "./UI/Card";
import "./Board.css";
import { floor } from "lodash";

const colors = ["red", "blue", "green", "yellow"];

const getRandomColorIndex = () => {
  return Math.floor(Math.random() * colors.length + 1.0) % colors.length;
};

function getAvg(ar) {
  const total = ar.reduce((acc, c) => acc + c, 0);
  return total / ar.length;
}

const Board = (props) => {
  const [gameTimes, setGameTimes] = useState([]);

  const [gameState, dispatchNewGame] = useReducer(
    (state, action) => {
      if (action.type === "NEXT_GAME") {
        return {
          round: state.round + 1,
          color: colors[getRandomColorIndex()],
          startTime: Date.now(),
          mode : props.mode
        };
      }
    },
    { round: 1, color: colors[getRandomColorIndex()], startTime: Date.now(), mode: props.mode }
  );

  const {onFinish : onGameFinish} = props; 

  const onColorMatchHandler = useCallback(() => {
      if (gameState.round <= 10) {
          const timeDiff = Date.now() - gameState.startTime;
          console.log(timeDiff);
          setGameTimes((prev) => {
              return [...prev, timeDiff];
            });
            
            dispatchNewGame({ type: "NEXT_GAME" });
        } else {
            // console.log(gameTimes);
            onGameFinish(getAvg(gameTimes));
        }
    }, [gameState.round, gameState.startTime, gameTimes, onGameFinish]);  //adding props in this 

    useEffect( ()=> {
        if(props.mode === 'auto' )
        {
            setTimeout(() => {onColorMatchHandler()}, 100);
        }
    }
    , [props.mode, onColorMatchHandler]);

  const getInstructionTextColor = () => {
    let color = "black";
    switch (props.level) {
      case "easy":
        color = gameState.color;
        break;
      case "hard":
        color = colors[getRandomColorIndex()];
        break;
      case "medium":
      default:
        break;
    }
    return color;
  };

  const getTileArray4colors = () => {
    const arrangements = [
      ["red", "green", "blue", "yellow"],
      ["red", "green", "yellow", "blue"],
      ["red", "blue", "green", "yellow"],
      ["red", "blue", "yellow", "green"],
      ["red", "green", "blue", "yellow"],
      ["red", "green", "yellow", "blue"],
      ["blue", "red", "green", "yellow"],
      ["blue", "red", "yellow", "green"],
      ["blue", "yellow", "red", "green"],
      ["blue", "yellow", "green", "red"],
      ["blue", "green", "yellow", "red"],
      ["blue", "green", "red", "yellow"],
      ["green", "red", "yellow", "blue"],
      ["green", "red", "blue", "yellow"],
      ["green", "blue", "yellow", "red"],
      ["green", "blue", "red", "yellow"],
      ["green", "yellow", "blue", "red"],
      ["green", "yellow", "red", "blue"],
      ["yellow", "green", "red", "blue"],
      ["yellow", "green", "blue", "red"],
      ["yellow", "blue", "green", "red"],
      ["yellow", "blue", "red", "green"],
      ["yellow", "red", "blue", "green"],
      ["yellow", "red", "green", "blue"],
    ];

    return arrangements[floor(Math.random() * arrangements.length)];
  };

  const tileArrangement = getTileArray4colors();

  return (
    <>
      <div
        className="instructions"
        style={{ color: getInstructionTextColor() }}
      >
        {gameState.color}
      </div>
      <div className="game-board">
        {tileArrangement.map((c) => {
          return (
            <Tile
              key={Math.random().toString()}
              color={c}
              curColor={gameState.color}
              onColorMatch={onColorMatchHandler}
            />
          );
        })}
      </div>
    </>
  );
};

export default Board;
