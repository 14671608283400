import React, { useState } from "react";
import Button from "./UI/Button";
import Card from "./UI/Card";

import classes from "./IntroModal.module.css";

const IntroModal = (props) => {

    const [level, setLevel] = useState(props.defaultLevel);



    const closeModalHandler = () => {
        props.onClose(level);
    }

    const radioItemSelectedHandler = (event) => {
        // console.log(event.target.id);
        setLevel(event.target.id);
    }

    const isEasy = level === 'easy';
    const isMedium = level === 'medium';
    const isHard = level === 'hard';
    // console.log(isEasy, isMedium, isHard);

  return (
    <>
      <div className={classes.backdrop} onClick={props.onClose} />
        <div className={classes.modalContainer}>
      <Card className={classes.modal}>
        <p className={classes.description}>
          This game tests your reaction speed. Once you click the <b>Start Game</b> button, you will be asked to click the tile
          displaying the color described in the text. The game ends after 10
          turns.
        </p>
        <div className={classes.levelContainer}>
          <div className={classes.radioItem}>
            <input name="radio" type="radio" id='easy' onClick={radioItemSelectedHandler} defaultChecked={isEasy?'checked': ''}/>
            <label for="easy" className={classes.radioSpan}>Easy</label>
          </div>
          <div className={classes.radioItem}>
            <input name="radio" type="radio" id='medium' onClick={radioItemSelectedHandler} defaultChecked={isMedium?'checked': ''}/>
            <label for="medium" className={classes.radioSpan} >Medium</label>
          </div>
          <div className={classes.radioItem}>
            <input name="radio" type="radio" id='hard' onClick={radioItemSelectedHandler} defaultChecked={isHard?'checked': ''}/>
            <label for="hard" className={classes.radioSpan}>Hard</label>
          </div>
        </div>
        <Button onClick={closeModalHandler}>Start Game</Button>
      </Card>
        </div>
    </>
  );
};

export default IntroModal;
