import React, { useCallback, useEffect, useReducer, useState } from "react";
import classes from "./Tile.module.css";

const Tile = (props) => {
  const [colors, dispatchColors] = useReducer(
    (state, action) => {
      if (action.type === "UPDATE") {
        return { mine: action.mine, correct: action.correct };
      }
    },
    { mine: props.color, correct: props.curColor }
  );

  const [selected, setSelected] = useState(false);

  const { onColorMatch } = props;
  const onClickHandler = useCallback(() => {
    setSelected(true);

    if (colors.mine === colors.correct) {
      //   console.log("You've selected the right color");
      onColorMatch();
    } else {
      console.log("Wrong color selected");
    }
  }, [colors, onColorMatch]);

  useEffect(() => {
    dispatchColors({
      type: "UPDATE",
      mine: props.color,
      correct: props.curColor,
    });
  }, [props.color, props.curColor]);

  // brief highlighting of selected tile
  useEffect(() => {
    setTimeout(() => {
      setSelected(false);
    }, 50);
  }, [selected]);

  return (
    <div
      className={`${props.className ? props.className : ""} ${classes.tile} ${selected ? classes.highlight : "" }`}
      style={{ backgroundColor: colors.mine, color: colors.mine }}
      onClick={onClickHandler}
    >
      {props.children}
    </div>
  );
};

export default Tile;
